import React from 'react';
import { graphql } from 'gatsby';
import clsx from 'clsx';
import Layout from '../components/layout/';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Cards from '../components/Cards/Cards';
import Hero from '../components/Hero/Hero';
import TextSection from '../components/TextSection/TextSection';

export default function PublicationsPage({data}) {
  return (
    <Layout>
      <div className="page">
        <Hero className={clsx(data.page.data.header_css_class.text, 'hero-publications')} text={data.page.data.header_text.html} image={data.page.data.header_image} />
        <Breadcrumb className={data.page.data.breadcrumb_css_class && data.page.data.breadcrumb_css_class.text} list={data.page.data.breadcrumb}/>
        <TextSection text={[data.page.data.paragraph.html]}/>
        <Cards 
          headline={data.page.data.key_publications_headline.html}
          cards={data.page.data.key_publications}
          className='bg-blue-light image-publications'
        />
        <Cards 
          headline={data.page.data.latest_publications_headline.html}
          cards={data.page.data.latest_publications}
          className='bg-blue-light image-publications'
        />
      </div>
    </Layout>
  )}

  export const Head = ({ data }) => (
    <SearchEngineOpt
      title={data.page.seo.title && data.page.seo.title.text}
      description={data.page.seo.description && data.page.seo.description.text}
      image={data.page.seo.image && data.page.seo.image.url}
      keywords={''} />
  )

export const PublicationsQuery = graphql`
query {
  page: prismicPublications {
    uid
    seo: data {
      title {
        text
      }
      description {
        text
      }
      image {
        url
      }
    }
    data {
      header_text {
        html
      }
      header_image {
        url
        alt
      }
      header_css_class {
        text
      }      
      breadcrumb {
        link_text {
          text
        }
        link_url {
          target
          slug
          link_type
          url
        }
      }
      breadcrumb_css_class {
        text
      }
      paragraph {
        html
      }
      key_publications_headline {
        html
      }
      key_publications {
        date {
          text
        }
        title {
          text
        }
        name {
          text
        }
        image {
          url
          alt
        }
        link {
          url
          target
        }
      }
      latest_publications_headline {
        html
      }
      latest_publications {
        date {
          text
        }
        title {
          text
        }
        name {
          text
        }
        image {
          url
          alt
        }
        link {
          url
          target
        }
      }      
    }
  }
}
`
